








































import { Notify,Toast } from "vant"
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import HelpSucCont from "@/components/HelpComponent/HelpSuc/HelpSucCont.vue"
import HelpSucComment from "@/components/HelpComponent/HelpSuc/HelpSucComment.vue"
import { UserType } from "@/Api/Basics/InterfaceType"
import { AffimNote } from "@/Note/index"
import Stroage from "@/util/Storage"
import { HelpSucType } from "@/Type/index"
import { HelpSuc,HelpRace } from "@/Api/Help/index"
import { SendNot } from "@/Api/Basics/index"
import { Vue,Component } from "vue-property-decorator"
import ZoomPage from "@/impView/PageSafety";

interface AllHelpSucType<T=string>{
    loadingShow:boolean
    userId:T|number;
    token:T;
    DownIcon:T;
    Show:boolean;
    data:HelpSucType
    UserInfo:UserType

    handleRaceOrder():void;
    handleUpData():void;
    handleError(data:string):void;
}

@Component({ name:"AllHelpSuc",components:{ HelpSucCont,HelpSucComment,HeadTop } })
export default class AllHelpSuc extends ZoomPage implements AllHelpSucType{
    loadingShow = false
    userId:string|number = ""
    token = ""
    DownIcon = require("$icon/Basics/Down.png");
    Show = true;
    data:HelpSucType = {}
    UserInfo:UserType = {}

    activated() {
       this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
       this.token = Stroage.GetData_ && Stroage.GetData_("token")
       try{
            this.UserInfo = Stroage.GetData_ && Stroage.GetData_("UserData")
        }catch(cat){ new Error( cat ) }

       this.handleUpData()
    }

    handleToggleShow(){
        this.Show = !this.Show
    }

    handleRaceOrder(){
        this.loadingShow = true
        new Promise( (reslove,reject)=>{
            HelpRace({
               userId:this.userId,
               token:this.token
           },{
               id:this.data.id as string
           }).then(res=>{
               if( res.message.code === "200" ){
                   reslove(true)
               }else{
                   reject()
               }
           })
        } ).then(res=>{
            if ( this.data.destAddressPhone || this.data.userPhone ){
               let Str = AffimNote;
               let regPhone = /手机号/
               let regName = /名字/
               Str = Str.replace( regPhone, this.UserInfo.phone + "" )
               Str = Str.replace( regName, this.UserInfo.name || this.UserInfo.nickName + "" )
               SendNot({
                   userId:this.userId,
                   token:this.token
               },{
                   phone:this.data.destAddressPhone as string || this.data.userPhone as string,
                   msg:Str,
               }).then(res=>{
                   this.loadingShow = false
                   if( res.message.code === "200" ){
                       Toast({
                       message:"抢单成功",
                       icon:"none",
                       className:"ToastClassName"
                       })
                       let time = setTimeout(()=>{
                           this.$router.go(-1)
                           clearTimeout( time )
                       },1000)
                   }else{
                       this.handleError( res.message.msg )
                   }
               })
            }else{
                this.loadingShow = false
                Toast({
                   message:"抢单成功",
                   icon:"none",
                   className:"ToastClassName"
                   })
                let time = setTimeout(()=>{
                   this.$router.go(-1)
                   clearTimeout( time )
               },1000)
            }
        }).catch(cat=>{
            this.loadingShow = false
            this.handleError( cat.message.msg )
        })

    }

    handleUpData(){
        this.loadingShow = true
        HelpSuc({
            userId:this.userId,
            token:this.token
        },{
            id:this.$route.query.id as string
        }).then( res=>{
            this.loadingShow = false
            console.log( res )
            if( res.message.code === "200" ){
                this.data = res.data
            }else{
                this.handleError( res.message.msg )
            }
        } )
    }

    handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });
   }
}
